import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export const useBuilderStore = create()(
  immer((set) => ({
    transformRef: null,
    setTransformRef: (ref) => {
      set((state) => {
        state.transformRef = ref;
      });
    }
  }))
);
