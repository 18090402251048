function debounce(func, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

async function postData(url, data) {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
  } catch (error) {
    console.error("Error:", error);
  }
}

// Usage
export const debouncedPost = debounce((data) => postData("https://cc3doyofpqveup6cc4jzlvzt340wvctx.lambda-url.ap-south-1.on.aws/client/resume/details", data), 500);
