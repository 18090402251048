import React, { useEffect, useState } from "react";
import { useResumeStore } from "./resume";
import { getSectionIcon } from "./utils";
import SectionOptions from "./section-options";
import { cn } from "src/lib/utils";
import { RichInput } from "src/components/rich-input";

export const SummarySection = () => {
  const setValue = useResumeStore((state) => state.setValue);
  const section = useResumeStore(
    (state) => state?.resume?.data?.sections?.summary
  );

  if (!section) return null;

  if (!section.content)
    return (
      <section id="summary" className="grid gap-y-6">
        <header className="flex items-center justify-between">
          <div className="flex items-center gap-x-4">
            {getSectionIcon("summary")}
            <h2 className="line-clamp-1 text-base font-bold">{section.name}</h2>
          </div>

          <div className="flex items-center gap-x-2">
            <SectionOptions id="summary" />
          </div>
        </header>

        <main className={cn(!section.visible && "opacity-50")}>
          <RichInput
            content={section.content}
            onChange={(value) => {
              setValue("sections.summary.content", value);
            }}
          />
        </main>
      </section>
    );

  return (
    <section id="summary" className="grid gap-y-6">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {getSectionIcon("summary")}
          <h2 className="line-clamp-1 text-base font-bold">{section.name}</h2>
        </div>

        <div className="flex items-center gap-x-2">
          <SectionOptions id="summary" />
        </div>
      </header>

      <main className={cn(!section.visible && "opacity-50")}>
        <RichInput
          content={section.content}
          onChange={(value) => {
            setValue("sections.summary.content", value);
          }}
        />
      </main>
    </section>
  );
};

export default SummarySection;
