import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CopySimple, DotsSixVertical, PencilSimple, TrashSimple, Eye, EyeSlash } from "@phosphor-icons/react";
import {
  ContextMenu,
  ContextMenuCheckboxItem,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "src/components/ui/context-menu";
import { cn } from "src/lib/utils";
import { motion } from "framer-motion";
import { Button } from "../ui/button";

export const SectionListItem = ({
  id,
  title,
  description,
  visible = true,
  onUpdate,
  onDuplicate,
  onDelete,
  onToggleVisibility,
}) => {
  const { setNodeRef, transform, transition, attributes, listeners, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
    zIndex: isDragging ? 100 : undefined,
    transition,
  };

  return (
    <motion.section
      ref={setNodeRef}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="border-x border-t bg-secondary/10 first-of-type:rounded-t last-of-type:rounded-b last-of-type:border-b"
    >
      <div style={style} className="flex transition-opacity">
        {/* Drag Handle */}
        <div
          {...listeners}
          {...attributes}
          className={cn(
            "flex w-5 cursor-move items-center justify-center",
            !isDragging && "hover:bg-secondary",
          )}
        >
          <DotsSixVertical weight="bold" size={12} />
        </div>

        <div
        className={cn(
          "flex-1 p-4",
          !visible && "opacity-50"
        )}
      >
        <h4 className="font-medium leading-relaxed">{title}</h4>
        {description && <p className="text-xs leading-relaxed text-muted-foreground">{description}</p>}
      </div>

      {/* Action Buttons */}
      <div className="flex items-center space-x-2 pr-4">
        <Button variant="ghost" size="icon" onClick={onToggleVisibility} title={visible ? "Hide" : "Show"}>
          {visible ? <Eye className="w-4 h-4" /> : <EyeSlash className="w-4 h-4" />}
        </Button>
        <Button variant="ghost" size="icon" onClick={onUpdate} title="Edit">
          <PencilSimple className="w-4 h-4" />
        </Button>
        <Button variant="ghost" size="icon" onClick={onDuplicate} title="Duplicate">
          <CopySimple className="w-4 h-4" />
        </Button>
        <Button variant="ghost" size="icon" onClick={onDelete} title="Delete">
          <TrashSimple className="w-4 h-4" />
        </Button>
      </div>
      </div>
    </motion.section>
  );
};
