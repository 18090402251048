import { zodResolver } from "@hookform/resolvers/zod";
// import { t } from "@lingui/macro";
import { X } from "@phosphor-icons/react";
import { defaultProject, projectSchema } from "../schemas";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { SectionDialog } from "../section-dialog";
import URLInput  from "../url-input";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { RichInput } from "src/components/rich-input";

const formSchema = projectSchema;

export const ProjectsDialog = () => {
  const form = useForm({
    defaultValues: defaultProject,
    resolver: zodResolver(formSchema),
  });

  const [pendingKeyword, setPendingKeyword] = useState("");

  return (
    <SectionDialog
      id="projects"
      form={form}
      defaultValues={defaultProject}
      pendingKeyword={pendingKeyword}
    >
      <Form {...form}>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <FormField
            name="name"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="description"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="date"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Date or Date Range</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="March 2023 - Present" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="url"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Website</FormLabel>
                <FormControl>
                  <URLInput {...field} placeholder="https://rxresu.me" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="summary"
            control={form.control}
            render={({ field }) => (
              <FormItem className="sm:col-span-2">
                <FormLabel>Summary</FormLabel>
                <FormControl>
                  <RichInput
                    {...field}
                    content={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="keywords"
            control={form.control}
            render={({ field }) => (
              <div className="space-y-3 sm:col-span-2">
                <FormItem>
                  <FormLabel>Keywords</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      value={pendingKeyword}
                      onChange={(e) => setPendingKeyword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ',') {
                          e.preventDefault();
                          if (pendingKeyword.trim()) {
                            field.onChange([...field.value, pendingKeyword.trim()]);
                            setPendingKeyword('');
                          }
                        }
                      }}
                    />
                  </FormControl>
                  <FormDescription>
                    {`You can add multiple keywords by separating them with a comma or pressing enter.`}
                  </FormDescription>
                  <FormMessage />
                </FormItem>

                <div className="flex flex-wrap items-center gap-x-2 gap-y-3">
                  <AnimatePresence>
                    {field.value.map((item, index) => (
                      <motion.div
                        key={item}
                        layout
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0, transition: { delay: index * 0.1 } }}
                        exit={{ opacity: 0, x: -50 }}
                      >
                        <Badge
                          className="cursor-pointer"
                          onClick={() => {
                            field.onChange(field.value.filter((v) => item !== v));
                          }}
                        >
                          <span className="mr-1">{item}</span>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              field.onChange(field.value.filter((v) => item !== v));
                            }}
                          >
                            <X size={12} weight="bold" />
                          </Button>
                        </Badge>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>
              </div>
            )}
          />
        </div>
      </Form>
    </SectionDialog>
  );
};

