import { Button } from "src/components/ui/button";
import { Combobox } from "src/components/ui/combobox";
import { Label } from "src/components/ui/label";
import { Switch } from "src/components/ui/switch";
import { Slider } from "src/components/ui/slider";
import { cn, fonts } from "../utils";
import { useCallback, useEffect, useState } from "react";
import WebFont from "webfontloader";

import { useResumeStore } from "../resume";
import { getSectionIcon } from "../utils";

const fontSuggestions = [
  "Open Sans",
  "Merriweather",
  "Roboto Condensed",
  "Playfair Display",
  "Lato",
  "Lora",
  "PT Sans",
  "PT Serif",
  "IBM Plex Sans",
  "IBM Plex Serif",
];

const families = fonts.map((font) => ({
  value: font.family,
  label: font.family,
}));

export const TypographySection = () => {
  const [subsets, setSubsets] = useState([]);
  const [variants, setVariants] = useState([]);
  const [fontLoaded, setFontLoaded] = useState(false);

  const setValue = useResumeStore((state) => state.setValue);
  const typography = useResumeStore(
    (state) => state?.resume?.data?.metadata?.typography
  );

  // Load all font variations and subsets
  const loadFont = useCallback(
    (fontFamily, variants = ["regular"], subset = "latin") => {
      return new Promise((resolve) => {
        WebFont.load({
          google: {
            families: variants.map((variant) => `${fontFamily}:${variant}`),
            subsets: [subset],
          },
          active: () => {
            setFontLoaded(true);
            resolve();
          },
          inactive: () => {
            console.warn(`Failed to load font: ${fontFamily}`);
            resolve();
          },
          classes: false,
        });
      });
    },
    []
  );

  // Load initial font suggestions
  useEffect(() => {
    const loadAllFonts = async () => {
      for (const font of fontSuggestions) {
        await loadFont(font);
      }
    };
    loadAllFonts();
  }, [loadFont]);

  // Load selected font when it changes
  useEffect(() => {
    if (typography && typography?.font?.family) {
      loadFont(
        typography.font.family,
        typography.font.variants,
        typography.font.subset
      );
    }
  }, [
    typography?.font?.family,
    typography?.font.variants,
    typography?.font.subset,
    loadFont,
  ]);

  useEffect(() => {
    const currentFont = fonts.find(
      (font) => font?.family === typography?.font?.family
    );
    if (currentFont) {
      setSubsets(
        currentFont.subsets.map((subset) => ({ value: subset, label: subset }))
      );
      setVariants(
        currentFont.variants.map((variant) => ({
          value: variant,
          label: variant,
        }))
      );
    }
  }, [typography?.font?.family]);

  const handleFontChange = async (fontFamily) => {
    setFontLoaded(false);
    setValue("metadata.typography.font.family", fontFamily);
    setValue("metadata.typography.font.subset", "latin");
    setValue("metadata.typography.font.variants", ["regular"]);
    await loadFont(fontFamily);
  };

  if (!typography) return null;

  return (
    <section id="typography" className="grid gap-y-6">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {getSectionIcon("typography")}
          <h2 className="line-clamp-1 text-base font-bold">{`Typography`}</h2>
        </div>
      </header>

      <main className="grid gap-y-4">
        <div className="grid grid-cols-2 gap-4">
          {fontSuggestions.map((font) => (
            <Button
              key={font}
              variant="outline"
              style={{ fontFamily: font }}
              disabled={typography?.font?.family === font}
              className={cn(
                "flex h-12 items-center justify-center overflow-hidden rounded border text-center text-sm ring-primary transition-colors hover:bg-secondary-accent focus:outline-none focus:ring-1 disabled:opacity-100",
                typography.font.family === font && "ring-1",
                !fontLoaded && "opacity-50"
              )}
              onClick={() => handleFontChange(font)}
            >
              {font}
            </Button>
          ))}
        </div>

        <div className="space-y-1.5">
          <Label>{`Font Family`}</Label>
          <Combobox
            options={families}
            value={typography?.font?.family}
            searchPlaceholder={`Search for a font family`}
            onValueChange={(value) => {
              setValue("metadata.typography.font.family", value);
              setValue("metadata.typography.font.subset", "latin");
              setValue("metadata.typography.font.variants", ["regular"]);
            }}
          />
        </div>

        <div className="grid grid-cols-2 gap-x-4">
          <div className="space-y-1.5">
            <Label>{`Font Subset`}</Label>
            <Combobox
              options={subsets}
              value={typography?.font?.subset}
              searchPlaceholder={`Search for a font subset`}
              onValueChange={(value) => {
                setValue("metadata.typography.font.subset", value);
              }}
            />
          </div>

          <div className="space-y-1.5">
            <Label>{`Font Variants`}</Label>
            <Combobox
              multiple
              options={variants}
              value={typography?.font?.variants}
              searchPlaceholder={`Search for a font variant`}
              onValueChange={(value) => {
                setValue("metadata.typography.font.variants", value);
              }}
            />
          </div>
        </div>

        <div className="space-y-1.5">
          <Label>{`Font Size`}</Label>
          <div className="flex items-center gap-x-4 py-1">
            <Slider
              min={12}
              max={18}
              step={0.05}
              value={[typography?.font?.size]}
              onValueChange={(value) => {
                setValue("metadata.typography.font.size", value[0]);
              }}
            />

            <span className="text-base font-bold">
              {typography?.font?.size}
            </span>
          </div>
        </div>

        <div className="space-y-1.5">
          <Label>{`Line Height`}</Label>
          <div className="flex items-center gap-x-4 py-1">
            <Slider
              min={0}
              max={3}
              step={0.05}
              value={[typography?.lineHeight]}
              onValueChange={(value) => {
                setValue("metadata.typography.lineHeight", value[0]);
              }}
            />

            <span className="text-base font-bold">
              {typography?.lineHeight}
            </span>
          </div>
        </div>

        <div className="space-y-1.5">
          <Label>{`Options`}</Label>

          <div className="flex items-center gap-x-4 py-2">
            <Switch
              id="metadata.typography.hideIcons"
              checked={typography?.hideIcons}
              onCheckedChange={(checked) => {
                setValue("metadata.typography.hideIcons", checked);
              }}
            />
            <Label htmlFor="metadata.typography.hideIcons">{`Hide Icons`}</Label>
          </div>

          <div className="flex items-center gap-x-4 py-2">
            <Switch
              id="metadata.typography.underlineLinks"
              checked={typography?.underlineLinks}
              onCheckedChange={(checked) => {
                setValue("metadata.typography.underlineLinks", checked);
              }}
            />
            <Label htmlFor="metadata.typography.underlineLinks">{`Underline Links`}</Label>
          </div>
        </div>
      </main>
    </section>
  );
};
