// import { cn } from "../utils";
import { cn } from "src/lib/utils";

// import { useArtboardStore } from "../store/artboard";
import { useResumeStore } from "../resume";
import { isUrl } from "../utils";




export const Picture = ({ className }) => {
  const picture = useResumeStore((state) => state.resume?.data?.basics?.picture);
  const fontSize = useResumeStore((state) => state.resume?.data?.metadata?.typography?.font?.size);

  if (!isUrl(picture.url) || picture.effects.hidden) return null;

  return (
    <img
      src={picture.url}
      alt="Profile"
      className={cn(
        "relative z-20 object-cover",
        picture.effects.border && "border-primary",
        picture.effects.grayscale && "grayscale",
        className,
      )}
      style={{
        maxWidth: `${picture.size}px`,
        aspectRatio: `${picture.aspectRatio}`,
        borderRadius: `${picture.borderRadius}px`,
        borderWidth: `${picture.effects.border ? fontSize / 3 : 0}px`,
      }}
    />
  );
};
