import { createId } from "@paralleldrive/cuid2";
import { CopySimple, PencilSimple, Plus } from "@phosphor-icons/react";
import { produce } from "immer";
import get from "lodash.get";
import { useEffect,useCallback } from "react";

import { useDialog } from "./dialog";
import { useResumeStore } from "./resume";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "src/components/ui/alert-dialog";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { Form } from "src/components/ui/form";

export const SectionDialog = ({
  id,
  form,
  defaultValues,
  pendingKeyword,
  children,
}) => {
  const { isOpen, mode, close, payload } = useDialog(id);

  const setValue = useResumeStore((state) => state.setValue);
  const section = useResumeStore((state) => {
    return get(state?.resume?.data?.sections, id);
  });

  const isCreate = mode === "create";
  const isUpdate = mode === "update";
  const isDelete = mode === "delete";
  const isDuplicate = mode === "duplicate";

  const onReset = useCallback(() => {
    if (isCreate) form.reset({ ...defaultValues, id: createId() });
    if (isUpdate) form.reset({ ...defaultValues, ...payload.item });
    if (isDuplicate) form.reset({ ...payload.item, id: createId() });
    if (isDelete) form.reset({ ...defaultValues, ...payload.item });
  }, [isCreate, isUpdate, isDuplicate, isDelete, form, defaultValues, payload]);

  useEffect(() => {
    if (isOpen) onReset();
  }, [isOpen, payload, onReset]);

  const onSubmit = (values) => {
    if (!section) return;

    if (isCreate || isDuplicate) {
      if (pendingKeyword && "keywords" in values) {
        values.keywords.push(pendingKeyword);
      }

      setValue(`sections.${id}.items`, produce(section.items, (draft) => {
        draft.push({ ...values, id: createId() });
      }));
    }

    if (isUpdate) {
      if (!payload.item?.id) return;

      if (pendingKeyword && "keywords" in values) {
        values.keywords.push(pendingKeyword);
      }

      setValue(`sections.${id}.items`, produce(section.items, (draft) => {
        const index = draft.findIndex((item) => item.id === payload.item?.id);
        if (index === -1) return;
        draft[index] = values;
      }));
    }

    if (isDelete) {
      if (!payload.item?.id) return;

      setValue(`sections.${id}.items`, produce(section.items, (draft) => {
        const index = draft.findIndex((item) => item.id === payload.item?.id);
        if (index === -1) return;
        draft.splice(index, 1);
      }));
    }

    close();
  };



  if (isDelete) {
    return (
      (<AlertDialog open={isOpen} onOpenChange={close}>
        <AlertDialogContent className="z-50">
          <Form {...form}>
            <form>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure you want to delete this item?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action can be reverted by clicking on the undo button in the floating toolbar.
                </AlertDialogDescription>
              </AlertDialogHeader>

              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction variant="destructive" onClick={form.handleSubmit(onSubmit)}>
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </form>
          </Form>
        </AlertDialogContent>
      </AlertDialog>)
    );
  }

  return (
    (<Dialog open={isOpen} onOpenChange={close}>
      <DialogContent className="z-50 max-h-[calc(100vh-4rem)] overflow-auto">
        <Form {...form}>
          <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>
                <div className="flex items-center space-x-2.5">
                  {isCreate && <Plus />}
                  {isUpdate && <PencilSimple />}
                  {isDuplicate && <CopySimple />}
                  <h2>
                    {isCreate && "Create a new item"}
                    {isUpdate && "Update an existing item"}
                    {isDuplicate && "Duplicate an existing item"}
                  </h2>
                </div>
              </DialogTitle>
            </DialogHeader>

            {children}

            <DialogFooter>
              <Button type="submit">
                {isCreate && "Create"}
                {isUpdate && "Save Changes"}
                {isDuplicate && "Duplicate"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>)
  );
};

