import BasicsSectionForm from "src/components/builder/BasicsSectionForm"
import { SummarySection } from "src/components/builder/summary"
import {
     ResizableHandle,
     ResizablePanel,
     ResizablePanelGroup,
} from "src/components/ui/resizable"
import { Separator } from "src/components/ui/separator"
import { Fragment, useEffect } from "react"
import { useResumeStore } from "src/components/builder/resume"
import { SectionBase } from "src/components/builder/section-base"
import { PlusCircle } from "lucide-react"
import { Button } from "src/components/ui/button"
import { RightSidebar } from "src/components/builder/right/sidebar"
import { BuilderLayout } from "src/components/builder/artboard/builder"
import { BuilderToolbar } from "src/components/builder/builder-toolbar"
import { resume } from "./dummy"
import { FirebaseContext } from "src/firebase";
import { useContext, useState } from "react";


export function ResizableHandleLayout() {
     const { currentUser, token } = useContext(FirebaseContext);
     const [loading, setLoading] = useState(true);
     const setValue = useResumeStore((state) => state.setValue);
     useEffect(() => {
          async function fetchData() {
               try {
                    setLoading(true);
                    setValue("userId", currentUser.id);
                    const resumeData = await fetch("https://cc3doyofpqveup6cc4jzlvzt340wvctx.lambda-url.ap-south-1.on.aws/client/resume/details?user_id=ea5c49a8-45f7-4302-9350-58d01b6b0be3").then((res) => res.json());
                    const data =
                         resumeData?.users?.length > 0 && resumeData.users[0]?.resume_config?.id
                              ? resumeData.users[0].resume_config
                              : resume;

                    useResumeStore.setState({ resume: data });
                    setValue("userId", currentUser.id);
                    console.log("useResumeStore.getState().resume");
                    console.log(useResumeStore.getState());
               } catch (error) {
                    console.error("Error fetching resume data:", error);
               } finally {
                    setLoading(false);
                    useResumeStore.temporal.getState().clear();
               }
          }
          fetchData();
     }, [])


     const addSection = useResumeStore((state) => state.addSection);
     const customSections = useResumeStore((state) => state?.resume?.data?.sections?.custom);
     if (loading) return (
          <div className="flex items-center justify-center w-screen min-h-screen text-2xl font-semibold">
               <div className="w-6 h-6 spinner-grow" />
               <div className="ml-3">Loading</div>
          </div>);
     if (!useResumeStore.getState()?.resume) return null;



     return (
          <>
               <div className="p-4 border-b rounded-b-md">
                    <h1 className="text-2xl font-bold bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 text-transparent bg-clip-text">Resume Builder</h1>
               </div>
               <ResizablePanelGroup
                    direction="horizontal"
                    className="min-h-screen max-w-full w-full rounded-lg border p-5 "
               >
                    <ResizablePanel col collapsible={false} minSize={15} defaultSize={25}>
                         <div className="flex flex-col h-full max-h-full overflow-auto items-center px-3  ">
                              <BasicsSectionForm />
                              <Separator className="mb-4" />
                              <SummarySection />
                              <div className="flex flex-col gap-2 w-full">
                                   <SectionBase
                                        id="profiles"
                                        title={(item) => item.network}
                                        description={(item) => item.username}
                                   />
                                   <Separator />
                                   <SectionBase
                                        id="experience"
                                        title={(item) => item.company}
                                        description={(item) => item.position}
                                   />
                                   <Separator />
                                   <SectionBase
                                        id="education"
                                        title={(item) => item.institution}
                                        description={(item) => item.area}
                                   />
                                   <Separator />
                                   <SectionBase
                                        id="skills"
                                        title={(item) => item.name}
                                        description={(item) => {
                                             if (item.description) return item.description;
                                             if (item.keywords.length > 0) return `${item.keywords.length} keywords`;
                                        }}
                                   />
                                   <Separator />
                                   <SectionBase
                                        id="languages"
                                        title={(item) => item.name}
                                        description={(item) => item.description}
                                   />
                                   <Separator />
                                   <SectionBase
                                        id="awards"
                                        title={(item) => item.title}
                                        description={(item) => item.awarder}
                                   />
                                   <Separator />
                                   <SectionBase
                                        id="certifications"
                                        title={(item) => item.name}
                                        description={(item) => item.issuer}
                                   />
                                   <Separator />
                                   <SectionBase
                                        id="interests"
                                        title={(item) => item.name}
                                        description={(item) => {
                                             if (item.keywords.length > 0) return `${item.keywords.length} keywords`;
                                        }}
                                   />
                                   <Separator />
                                   <SectionBase
                                        id="projects"
                                        title={(item) => item.name}
                                        description={(item) => item.description}
                                   />
                                   <Separator />
                                   <SectionBase
                                        id="publications"
                                        title={(item) => item.name}
                                        description={(item) => item.publisher}
                                   />
                                   <Separator />
                                   <SectionBase
                                        id="volunteer"
                                        title={(item) => item.organization}
                                        description={(item) => item.position}
                                   />
                                   <Separator />
                                   <SectionBase
                                        id="references"
                                        title={(item) => item.name}
                                        description={(item) => item.description}
                                   />
                              </div>

                              {/* Custom Sections */}
                              {customSections && Object.values(customSections).map((section) => (
                                   <Fragment key={section.id}>
                                        <Separator className="my-2" />
                                        <SectionBase
                                             id={`custom.${section.id}`}
                                             title={(item) => item.name}
                                             description={(item) => item.description}
                                        />
                                   </Fragment>
                              ))}


                              <Separator className="my-4" />

                              <Button className="my-2 p-3" size="xl" variant="outline" onClick={addSection}>
                                   <PlusCircle />
                                   <span className="ml-2">{`Add a new section`}</span>
                              </Button>



                         </div>
                    </ResizablePanel>
                    <ResizableHandle withHandle />
                    <ResizablePanel collapsible={false} minSize={50} defaultSize={50}>
                         <div className="flex h-full items-center justify-center p-6">
                              <BuilderLayout />
                              <BuilderToolbar />
                         </div>
                    </ResizablePanel>
                    <ResizableHandle withHandle />
                    <ResizablePanel collapsible={false} minSize={15} defaultSize={25}>
                         <div className="flex h-full items-center min-w-full justify-center p-6">
                              <RightSidebar />
                         </div>
                    </ResizablePanel>
               </ResizablePanelGroup>
          </>
     )
}
