import gql from "graphql-tag";

export const GET_PROFILE_DATA = gql`
  query GetUserBasicProfileData($id: uuid!) {
    users(where: { id: { _eq: $id } }) {
      fullName
      dob
      id
      gender
      uid
      current_location
      short_bio
      profile_picture
      resume
    }
  }
`;

export const UPDATE_PROFILE_DATA = gql`
  mutation UpdateUserBasicProfileData(
    $id: uuid!
    $dob: date
    $fullName: String
    $gender: String
    $current_location: String
    $short_bio: String
    $profile_picture: String
  ) {
    update_users(
      where: { id: { _eq: $id } }
      _set: {
        dob: $dob
        fullName: $fullName
        gender: $gender
        current_location: $current_location
        short_bio: $short_bio
        profile_picture: $profile_picture
      }
    ) {
      returning {
        id
        dob
        fullName
        gender
        current_location
        short_bio
        profile_picture
      }
    }
  }
`;

export const UPDATE_PROFILE_PIC_DATA = gql`
  mutation UpdateUserProfilePicture($id: uuid!, $profile_picture: String) {
    update_users(
      where: { id: { _eq: $id } }
      _set: { profile_picture: $profile_picture }
    ) {
      returning {
        id
        profile_picture
      }
    }
  }
`;
