import React, { useState } from "react";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "src/components/ui/card";
import { useResumeStore } from "./resume";
// Assume these components are defined elsewhere
import PictureSection from "./picture-section";
import URLInput from "./url-input";
import CustomFieldsSection from "./custom-fields-section";
import { basicsSchema } from "./schemas";

// Assume this function is defined elsewhere
import { getSectionIcon } from "./utils";
import { SummarySection } from "./summary";

export default function BasicsSectionForm() {
  const setValue = useResumeStore((state) => state.setValue);
  const basics = useResumeStore((state) => state?.resume?.data?.basics || null);

  if (!basics) return null;

  // This is a simple validation. You might want to use a more robust solution.
  const hasError = (field) => {
    if (field === "name" && basics.name.length < 2) return true;
    if (field === "email" && !basics.email.includes("@")) return true;
    return false;
  };

  return (
    <section id="basics" className="grid gap-y-6">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {getSectionIcon("basics")}
          <h2 className="line-clamp-1 text-base font-bold">{`Basics`}</h2>
        </div>
      </header>

      <main className="grid gap-4 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <PictureSection />
        </div>

        <div className="space-y-1.5 sm:col-span-2">
          <Label htmlFor="basics.name">{`Full Name`}</Label>
          <Input
            id="basics.name"
            value={basics.name}
            hasError={
              !basicsSchema
                .pick({ name: true })
                .safeParse({ name: basics.name }).success
            }
            onChange={(event) => {
              setValue("basics.name", event.target.value);
            }}
          />
        </div>

        <div className="space-y-1.5 sm:col-span-2">
          <Label htmlFor="basics.headline">{`Headline`}</Label>
          <Input
            id="basics.headline"
            value={basics.headline}
            onChange={(event) => {
              setValue("basics.headline", event.target.value);
            }}
          />
        </div>

        <div className="space-y-1.5">
          <Label htmlFor="basics.email">{`Email`}</Label>
          <Input
            id="basics.email"
            placeholder="john.doe@example.com"
            value={basics.email}
            hasError={
              !basicsSchema
                .pick({ email: true })
                .safeParse({ email: basics.email }).success
            }
            onChange={(event) => {
              setValue("basics.email", event.target.value);
            }}
          />
        </div>

        <div className="space-y-1.5">
          <Label htmlFor="basics.url">{`Website`}</Label>
          <URLInput
            id="basics.url"
            value={basics.url}
            placeholder="https://example.com"
            onChange={(value) => {
              setValue("basics.url", value);
            }}
          />
        </div>

        <div className="space-y-1.5">
          <Label htmlFor="basics.phone">{`Phone`}</Label>
          <Input
            id="basics.phone"
            placeholder="+1 (123) 4567 7890"
            value={basics.phone}
            onChange={(event) => {
              setValue("basics.phone", event.target.value);
            }}
          />
        </div>

        <div className="space-y-1.5">
          <Label htmlFor="basics.location">{`Location`}</Label>
          <Input
            id="basics.location"
            value={basics.location}
            onChange={(event) => {
              setValue("basics.location", event.target.value);
            }}
          />
        </div>

        <CustomFieldsSection className="sm:col-span-2" />
        {/* <SummarySection /> */}
      </main>
    </section>
  );
}
