export const FUNCTION_BASE_URL =
  "https://us-central1-hackerrank-talentio.cloudfunctions.net";
// "http://localhost:5001/hackerrank-talentio/us-central1";

export const JOB_TYPE_LABELS = {
  in_office: "Regular (In-office/On-field)",
  work_from_home: "Work from home",
  flexible: "Flexible",
};
export const JOB_TYPE_ENUM = {
  IN_OFFICE: "in_office",
  WORK_FROM_HOME: "work_from_home",
  FLEXIBLE: "flexible",
};

export const JOB_CONTRACT_TYPE_ENUM = {
  FULL_TIME: "full_time",
  PART_TIME: "part_time",
  INTERNSHIP: "internship",
};

export const JOB_CONTRACT_TYPE_LABELS = {
  [JOB_CONTRACT_TYPE_ENUM.FULL_TIME]: "Full Time",
  [JOB_CONTRACT_TYPE_ENUM.PART_TIME]: "Part Time",
  [JOB_CONTRACT_TYPE_ENUM.INTERNSHIP]: "Internship",
};

export const JOB_PERKS_LABELS = {
  "5_days": "5 days a week",
  transportation: "Cab/Transportation facility",
  health_insurance: "Health Insurance",
  life_insurance: "Life Insurance",
  free_snacks: "Free snacks and beverages",
  informal_dress: "Informal dress code",
};

export const skillLevelMap = {
  25: "Beginner",
  50: "Intermediate",
  75: "Advanced",
  100: "Expert",
};

export const colors = [
  "#475569", // slate-600
  "#57534e", // stone-600
  "#dc2626", // red-600
  "#ea580c", // orange-600
  "#d97706", // amber-600
  "#ca8a04", // yellow-600
  "#65a30d", // lime-600
  "#16a34a", // green-600
  "#059669", // emerald-600
  "#0d9488", // teal-600
  "#0891b2", // cyan-600
  "#0284c7", // sky-600
  "#2563eb", // blue-600
  "#4f46e5", // indigo-600
  "#7c3aed", // violet-600
  "#9333ea", // purple-600
  "#c026d3", // fuchsia-600
  "#db2777", // pink-600
  "#e11d48", // rose-600
];
