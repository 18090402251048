import React from "react";
import { Route, Routes } from "react-router-dom";
import { ResizableHandleLayout } from "./layout";
import DialogProvider from "./dialog";
import { Print } from "./print";

function Builder() {
  return (
    <DialogProvider>
      <Routes>
        <Route path="/" element={<ResizableHandleLayout />} />
        <Route path="/print" element={<Print />} />
      </Routes>
    </DialogProvider>
  );
}

export default Builder;
