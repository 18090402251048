import { ScrollArea } from "src/components/ui/scroll-area";
import { useRef } from "react";
import { TemplateSection } from "./template";
import { TypographySection } from "./typography";
import { PageSection } from "./pageSection";
import { ThemeSection } from "./theme";


export const RightSidebar = () => {
  const containterRef = useRef(null);

  

  return (
    <div className="flex flex-grow bg-secondary-accent/30">
      <ScrollArea orientation="vertical" className="h-screen flex-1 pb-16 lg:pb-0">
        <div ref={containterRef} className="grid gap-y-6 p-6 @container/right">
          <TemplateSection />
          <TypographySection />
          <PageSection/>
          <ThemeSection/>
        </div>
      </ScrollArea>

      
    </div>
  );
};
