import { useState } from "react";
import { createId } from "@paralleldrive/cuid2";
import { DotsSixVertical, Envelope, Plus, X } from "@phosphor-icons/react";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/components/ui/tooltip";
import { cn } from "src/lib/utils";
import { Reorder, useDragControls, AnimatePresence } from "framer-motion";
import { useResumeStore } from "./resume";

const CustomField = ({ field, onChange, onRemove }) => {
  const controls = useDragControls();

  const handleChange = (key, value) => {
    onChange({ ...field, [key]: value });
  };

  return (
    <Reorder.Item
      value={field}
      id={field.id}
      dragListener={false}
      dragControls={controls}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
    >
      <div className="flex items-end justify-between space-x-2">
        <Button
          size="icon"
          variant="link"
          className="shrink-0"
          onPointerDown={(event) => {
            controls.start(event);
          }}
        >
          <DotsSixVertical />
        </Button>
        <Popover>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <PopoverTrigger asChild>
                  <Button size="icon" variant="ghost">
                    {field.icon ? <i className={cn(`ph ph-${field.icon}`)} /> : <Envelope />}
                  </Button>
                </PopoverTrigger>
              </TooltipTrigger>
              <TooltipContent>Icon</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <PopoverContent className="p-1.5">
            <Input
              value={field.icon}
              placeholder="Enter Phosphor Icon"
              onChange={(event) => {
                onChange({ ...field, icon: event.target.value });
              }}
            />
          </PopoverContent>
        </Popover>
        <Input
          placeholder="Name"
          value={field.name}
          className="ml-0"
          onChange={(event) => {
            handleChange("name", event.target.value);
          }}
        />
        <Input
          placeholder="Value"
          value={field.value}
          onChange={(event) => {
            handleChange("value", event.target.value);
          }}
        />
        <Button
          size="icon"
          variant="link"
          className="ml-0 shrink-0"
          onClick={() => {
            onRemove(field.id);
          }}
        >
          <X />
        </Button>
      </div>
    </Reorder.Item>
  );
};

const CustomFieldsSection = ({ className }) => {
  const setValue = useResumeStore((state) => state.setValue);
  const customFields = useResumeStore((state) => state.resume.data.basics.customFields);

  const onAddCustomField = () => {
    setValue("basics.customFields", [
      ...customFields,
      { id: createId(), icon: "", name: "", value: "" },
    ]);
  };

  const onChangeCustomField = (field) => {
    const index = customFields.findIndex((item) => item.id === field.id);
    const newCustomFields = JSON.parse(JSON.stringify(customFields));
    newCustomFields[index] = field;

    setValue("basics.customFields", newCustomFields);
  };

  const onReorderCustomFields = (values) => {
    setValue("basics.customFields", values);
  };

  const onRemoveCustomField = (id) => {
    setValue(
      "basics.customFields",
      customFields.filter((field) => field.id !== id),
    );
  };

  return (
    <div className={cn("space-y-4", className)}>
    <AnimatePresence>
      <Reorder.Group
        axis="y"
        className="space-y-4"
        values={customFields}
        onReorder={onReorderCustomFields}
      >
        {customFields.map((field) => (
          <CustomField
            key={field.id}
            field={field}
            onChange={onChangeCustomField}
            onRemove={onRemoveCustomField}
          />
        ))}
      </Reorder.Group>
    </AnimatePresence>

    <Button variant="link" onClick={onAddCustomField}>
      <Plus className="mr-2" />
      <span>{`Add a custom field`}</span>
    </Button>
  </div>
  );
};

export default CustomFieldsSection;

