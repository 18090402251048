import { useContext, useMemo, useRef, useState, useEffect } from "react";
import { z } from "zod";
import { motion } from "framer-motion";
import { Aperture, Trash, UploadSimple } from "@phosphor-icons/react";
import { Avatar, AvatarImage } from "src/components/ui/avatar";
import { Button, buttonVariants } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import PictureOptions from "./picture-options";
import { useResumeStore } from "./resume";
import { FirebaseContext } from "src/firebase";
import { UPDATE_PROFILE_PIC_DATA } from "src/pages/settings/sections/aboutyou/queries";
import { useMutation, useQuery } from "@apollo/client";
import { cn } from "src/lib/utils";
import { toast } from "react-toastify";
import { GET_PROFILE_DATA } from "src/pages/settings/sections/aboutyou/queries";

export const PictureSection = () => {
  const { currentUser, firebase } = useContext(FirebaseContext);
  const inputRef = useRef(null);
  const setValue = useResumeStore((state) => state.setValue);
  const picture = useResumeStore((state) => state?.resume?.data?.basics?.picture);

  const [isProcessing, setIsProcessing] = useState(false);

  const { data, loading, error } = useQuery(GET_PROFILE_DATA, {
    variables: { id: currentUser.id },
    onCompleted: (data) => {
      setValue("basics.picture.url", data.users[0]?.profile_picture || "");
    },
    onError: (err) => {
      toast.error(`Failed to load profile: ${err.message}`);
    },
  });

  useEffect(() => {
    if(!picture || !picture?.url) {
      setValue("basics.picture.url", data?.users[0]?.profile_picture || "");
    }
  }, [data, picture, setValue]);

  const [updateProfileData] = useMutation(UPDATE_PROFILE_PIC_DATA, {
    onCompleted: () => {
      setIsProcessing(false);
      toast.success("Profile updated successfully.");
    },
    onError: (err) => {
      setIsProcessing(false);
      toast.error(`Profile update failed: ${err.message}`);
    },
  });

  const isValidUrl = useMemo(() => z.string().url().safeParse(picture.url).success, [picture.url]);

  const onSelectImage = async (event) => {
    if (!event.target.files || event.target.files.length === 0) return;

    const file = event.target.files[0];
    setIsProcessing(true);

    try {
      const snapshot = await firebase
        .storage()
        .ref(`profile_images/${currentUser.uid}.jpg`)
        .put(file);

      const downloadURL = await snapshot.ref.getDownloadURL();
      await updateProfileData({
        variables: { id: currentUser.id, profile_picture: downloadURL },
      });

      setValue("basics.picture.url", downloadURL);
    } catch (err) {
      toast.error(`Image upload failed: ${err.message}`);
      setIsProcessing(false);
    }
  };

  const onAvatarClick = () => {
    inputRef.current?.click();
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">Error loading profile data.</div>;

  return (
    <div className="flex items-center gap-x-4">
      <div
        className={cn(
          "group relative cursor-pointer",
          isProcessing && "pointer-events-none opacity-50"
        )}
        onClick={onAvatarClick}
      >
        <Avatar className="size-14 bg-secondary">
          {isProcessing  ? (
            <div className="animate-pulse bg-black h-full w-full rounded-full" />
          ) : (
            <AvatarImage src={picture?.url} />
          )}
        </Avatar>

        <div className="absolute inset-0 flex items-center justify-center rounded-full bg-background/30 opacity-0 transition-opacity group-hover:opacity-100">
          <UploadSimple size={16} weight="bold" />
        </div>
      </div>

      <div className="flex w-full flex-col gap-y-1.5">
        <Label htmlFor="basics.picture.url">Picture</Label>
        <div className="flex items-center gap-x-2">
          <input
            ref={inputRef}
            hidden
            type="file"
            accept="image/*"
            onChange={onSelectImage}
            disabled={isProcessing}
          />

          <Input
            id="basics.picture.url"
            placeholder="https://..."
            value={picture?.url}
            onChange={(e) => setValue("basics.picture.url", e.target.value)}
            disabled={isProcessing}
          />

          {isValidUrl && (
            <Popover>
              <PopoverTrigger asChild>
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className={cn(buttonVariants({ size: "icon", variant: "ghost" }))}
                >
                  <Aperture />
                </motion.button>
              </PopoverTrigger>
              <PopoverContent className="w-[360px]">
                <PictureOptions />
              </PopoverContent>
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
};

export default PictureSection;
