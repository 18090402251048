import { createId } from "@paralleldrive/cuid2";

export const resume = {
    "id": createId(),
    "title": "",
    "slug": "",
    "data": {
        "basics": {
            "url": {
                "href": "",
                "label": ""
            },
            "name": "",
            "email": "",
            "phone": "",
            "picture": {
                "url": "",
                "size": 64,
                "effects": {
                    "border": false,
                    "hidden": false,
                    "grayscale": false
                },
                "aspectRatio": 1,
                "borderRadius": 0
            },
            "headline": "",
            "location": "",
            "customFields": [

            ]
        },
        "metadata": {
            "css": {
                "value": "* {\n\toutline: 1px solid #000;\n\toutline-offset: 4px;\n}",
                "visible": false
            },
            "page": {
                "format": "a4",
                "margin": 18,
                "options": {
                    "breakLine": true,
                    "pageNumbers": true
                }
            },
            "notes": "",
            "theme": {
                "text": "#000000",
                "primary": "#dc2626",
                "background": "#ffffff"
            },
            "layout": [
                [
                    [
                        "profiles",
                        "summary",
                        "experience",
                        "education",
                        "projects",
                        "volunteer",
                        "references",
                    ],
                    [
                        "skills",
                        "interests",
                        "certifications",
                        "awards",
                        "publications",
                        "languages"
                    ]
                ]
            ],
            "template": "gengar",
            "typography": {
                "font": {
                    "size": 14,
                    "family": "IBM Plex Serif",
                    "subset": "latin",
                    "variants": [
                        "regular",
                        "italic",
                        "600"
                    ]
                },
                "hideIcons": false,
                "lineHeight": 1.5,
                "underlineLinks": true
            }
        },
        "sections": {
            "awards": {
                "id": "awards",
                "name": "Awards",
                "items": [],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            },
            "custom": {

            },
            "skills": {
                "id": "skills",
                "name": "Skills",
                "items": [

                ],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            },
            "summary": {
                "id": "summary",
                "name": "Summary",
                "columns": 1,
                "content": "",
                "visible": true,
                "separateLinks": true
            },
            "profiles": {
                "id": "profiles",
                "name": "Profiles",
                "items": [

                ],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            },
            "projects": {
                "id": "projects",
                "name": "Projects",
                "items": [

                ],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            },
            "education": {
                "id": "education",
                "name": "Education",
                "items": [],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            },
            "interests": {
                "id": "interests",
                "name": "Interests",
                "items": [],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            },
            "languages": {
                "id": "languages",
                "name": "Languages",
                "items": [

                ],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            },
            "volunteer": {
                "id": "volunteer",
                "name": "Volunteering",
                "items": [],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            },
            "experience": {
                "id": "experience",
                "name": "Experience",
                "items": [

                ],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            },
            "references": {
                "id": "references",
                "name": "References",
                "items": [],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            },
            "publications": {
                "id": "publications",
                "name": "Publications",
                "items": [],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            },
            "certifications": {
                "id": "certifications",
                "name": "Certifications",
                "items": [

                ],
                "columns": 1,
                "visible": true,
                "separateLinks": true
            }
        }
    },
    "visibility": "private",
    "locked": false,
    "userId": "",
};