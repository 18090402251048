import {
  ArrowClockwise,
  ArrowCounterClockwise,
  CircleNotch,
  ClockClockwise,
  CubeFocus,
  FilePdf,
  Hash,
  LineSegment,
  LinkSimple,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
} from "@phosphor-icons/react";
import { motion } from "framer-motion";

// import { usePrintResume } from "src/services/resume";
import { useBuilderStore } from "./builder";
import { useResumeStore, useTemporalResumeStore } from "./resume";

import { Button } from "src/components/ui/button";
import { Separator } from "src/components/ui/separator";
import { Toggle } from "src/components/ui/toggle";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/components/ui/tooltip";

const openInNewTab = (url) => {
  const win = window.open(url, "_blank");
  if (win) win.focus();
};

export const BuilderToolbar = () => {
  const transformRef = useBuilderStore((state) => state.transformRef);
  const setValue = useResumeStore((state) => state.setValue);
  const undo = useTemporalResumeStore((state) => state?.undo);
  const redo = useTemporalResumeStore((state) => state?.redo);

  const pageOptions = useResumeStore((state) => state?.resume?.data?.metadata?.page?.options);

  const onZoomIn = () => transformRef?.zoomIn(0.2);
  const onZoomOut = () => transformRef?.zoomOut(0.2);
  const onResetView = () => {
    transformRef?.resetTransform(0);
    setTimeout(() => transformRef?.centerView(0.8, 0), 10);
  };
  const onCenterView = () => transformRef?.centerView();

  if (!pageOptions) return null;


  return (
    <TooltipProvider>
      <motion.div
      style={{ maxWidth: 'fit-content', height: 'fit-content' }}
        className="fixed inset-x-0 bottom-0 mx-auto hidden py-6 text-center md:block">
        <div className="inline-flex items-center justify-center rounded-full bg-background px-4 shadow-xl">
          {/* Undo/Redo */}
          {/* <Tooltip>
            <TooltipTrigger asChild>
              <Button size="icon" variant="ghost" className="rounded-none" onClick={undo}>
                <ArrowCounterClockwise />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Undo</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button size="icon" variant="ghost" className="rounded-none" onClick={redo}>
                <ArrowClockwise />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Redo</TooltipContent>
          </Tooltip> */}

          {/* <Separator orientation="vertical" className="h-9" /> */}

          {/* Zoom Controls */}
          <Tooltip>
            <TooltipTrigger asChild>
              <Button size="icon" variant="ghost" className="rounded-none" onClick={onZoomIn}>
                <MagnifyingGlassPlus />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Zoom In</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button size="icon" variant="ghost" className="rounded-none" onClick={onZoomOut}>
                <MagnifyingGlassMinus />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Zoom Out</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button size="icon" variant="ghost" className="rounded-none" onClick={onResetView}>
                <ClockClockwise />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Reset Zoom</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button size="icon" variant="ghost" className="rounded-none" onClick={onCenterView}>
                <CubeFocus />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Center Artboard</TooltipContent>
          </Tooltip>

          <Separator orientation="vertical" className="h-9" />

          <Tooltip>
            <TooltipTrigger asChild>
              <Toggle
                className="rounded-none"
                pressed={pageOptions.breakLine}
                onPressedChange={(pressed) => {
                  setValue("metadata.page.options.breakLine", pressed);
                }}>
                <LineSegment />
              </Toggle>
            </TooltipTrigger>
            <TooltipContent>Toggle Page Break Line</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Toggle
                className="rounded-none"
                pressed={pageOptions.pageNumbers}
                onPressedChange={(pressed) => {
                  setValue("metadata.page.options.pageNumbers", pressed);
                }}>
                <Hash />
              </Toggle>
            </TooltipTrigger>
            <TooltipContent>Toggle Page Numbers</TooltipContent>
          </Tooltip>

          <Separator orientation="vertical" className="h-9" />

         

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                size="icon"
                variant="ghost"
                disabled={false}
                className="rounded-none"
                >
                {false ? <CircleNotch className="animate-spin" /> : <FilePdf />}
              </Button>
            </TooltipTrigger>
            <TooltipContent>Download PDF</TooltipContent>
          </Tooltip>
        </div>
      </motion.div>
    </TooltipProvider>
  );
};

