import * as React from "react";
import {
  AudioWaveform,
  BookOpen,
  Bot,
  Command,
  Frame,
  GalleryVerticalEnd,
  Map,
  PieChart,
  Settings2,
  SquareTerminal,
} from "lucide-react";
import {
  AiFillWechat,
  AiOutlineCode,
  AiOutlineCodeSandbox,
  AiOutlineCompass,
  AiOutlineFileText,
  AiOutlineHome,
  AiOutlineSchedule,
  AiFillAudio,
} from "react-icons/ai";
import { doSignOut } from "src/firebase";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import { FaHackerrank, FaBars } from "react-icons/fa";
import { GoSignOut } from "react-icons/go";
import { FaTrophy, FaMedal } from "react-icons/fa";
import {
  HiOutlineSupport,
  HiOutlineUserGroup,
  HiOutlineVideoCamera,
} from "react-icons/hi";
import { IoBriefcaseOutline, IoDocumentTextOutline } from "react-icons/io5";
import {
  MdClose,
  MdEvent,
  MdOutlineAssessment,
  MdOutlineGroups,
} from "react-icons/md";
import { ImBook } from "react-icons/im";

import { NavMain } from "./DashboardNavMain";
import { NavProjects } from "./nav-projects";
import { NavUser } from "./nav-user";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
  useSidebar,
} from "./ui/DashboardSidebar";
import { FirebaseContext } from "src/firebase";
import { twMerge } from "tailwind-merge";
import { SidebarProvider, SidebarTrigger } from "./ui/DashboardSidebar";
import { Build } from "@mui/icons-material";
import { ReadCvLogo } from "@phosphor-icons/react";

// This is sample data.
const data = {
  user: {
    name: "shadcn",
    email: "m@example.com",
    avatar: "/avatars/shadcn.jpg",
  },
  teams: [
    {
      name: "Acme Inc",
      logo: GalleryVerticalEnd,
      plan: "Enterprise",
    },
    {
      name: "Acme Corp.",
      logo: AudioWaveform,
      plan: "Startup",
    },
    {
      name: "Evil Corp.",
      logo: Command,
      plan: "Free",
    },
  ],
  navMain: [
    {
      title: "Playground",
      url: "#",
      icon: SquareTerminal,
      isActive: true,
      items: [
        {
          title: "History",
          url: "#",
        },
        {
          title: "Starred",
          url: "#",
        },
        {
          title: "Settings",
          url: "#",
        },
      ],
    },
    {
      title: "Models",
      url: "#",
      icon: Bot,
      items: [
        {
          title: "Genesis",
          url: "#",
        },
        {
          title: "Explorer",
          url: "#",
        },
        {
          title: "Quantum",
          url: "#",
        },
      ],
    },
    {
      title: "Documentation",
      url: "#",
      icon: BookOpen,
      items: [
        {
          title: "Introduction",
          url: "#",
        },
        {
          title: "Get Started",
          url: "#",
        },
        {
          title: "Tutorials",
          url: "#",
        },
        {
          title: "Changelog",
          url: "#",
        },
      ],
    },
    {
      title: "Settings",
      url: "#",
      icon: Settings2,
      items: [
        {
          title: "General",
          url: "#",
        },
        {
          title: "Team",
          url: "#",
        },
        {
          title: "Billing",
          url: "#",
        },
        {
          title: "Limits",
          url: "#",
        },
      ],
    },
  ],
  projects: [
    {
      name: "Design Engineering",
      url: "#",
      icon: Frame,
    },
    {
      name: "Sales & Marketing",
      url: "#",
      icon: PieChart,
    },
    {
      name: "Travel",
      url: "#",
      icon: Map,
    },
  ],
};

export function DashboardSideBar({ ref, ...props }) {
  const { currentUser } = React.useContext(FirebaseContext);
  const { state } = useSidebar();

  const navigations = React.useMemo(() => {
    return [
      {
        pathMatch: "/home/dashboard",
        href: "/home/dashboard",
        icon: AiOutlineHome,
        label: "Dashboard",
        condition: true,
      },
      {
        pathMatch: "/home/tests",
        href: "/home/tests",
        icon: IoDocumentTextOutline,
        label: "Assessments",
        condition: currentUser.university.config.test,
      },
      {
        pathMatch: "/home/contests",
        href: "/home/contests",
        icon: FaTrophy,
        label: "Contest Calendar",
        condition: currentUser.university.config.hackerrank,
      },

      {
        pathMatch: "/home/test_challenges",
        href: "/home/test_challenges",
        icon: FaHackerrank,
        label: "Global Platform Assessments",
        condition: currentUser.university.config.hackerrank,
      },
      {
        pathMatch: "/home/courses",
        href: "/home/courses",
        icon: ImBook,
        label: "Courses",
        condition: currentUser.university.config.courses,
      },
      {
        pathMatch: "/home/events",
        href: "/home/events",
        icon: MdEvent,
        label: "Events",
        condition: currentUser.university.config.events,
      },
      {
        pathMatch: "/home/challenges",
        href: "/home/challenges",
        icon: AiOutlineFileText,
        label: "Challenges",
        condition: currentUser.university.config.challenges,
      },
      {
        pathMatch: "/home/stats",
        href: "/home/stats/dashboard",
        icon: AiOutlineCompass,
        label: "Explore",
        condition: currentUser.university.config.explore,
      },
      {
        pathMatch: "/home/project_assignments",
        href: "/home/project_assignments",
        icon: MdOutlineAssessment,
        label: "Projects",
        condition: currentUser.university.config.projects_assign,
      },
      {
        pathMatch: "/home/jobs",
        href: "/home/jobs",
        icon: IoBriefcaseOutline,
        label: "Jobs",
        condition: currentUser.university.config.jobs,
      },
      {
        pathMatch: "/home/schedule",
        href: "/home/schedule",
        icon: AiOutlineSchedule,
        label: "Schedule",
        condition: currentUser.university.config.schedule,
      },
      {
        pathMatch: "/home/live_session",
        href: "/home/live_session",
        icon: MdOutlineGroups,
        label: "Live Session",
        condition: currentUser.university.config.live_session,
      },
      {
        pathMatch: "/home/communities",
        href: "/home/communities",
        icon: HiOutlineUserGroup,
        label: "Communities",
        condition: currentUser.university.config.communities,
      },
      {
        pathMatch: "/home/sandbox",
        href: "/home/sandbox",
        icon: AiOutlineCodeSandbox,
        label: "Sandbox",
        condition: currentUser.university.config.sandbox,
      },
      {
        pathMatch: "/home/expert-connect",
        href: "/home/expert-connect",
        icon: AiFillWechat,
        label: "Expert Connect",
        condition: currentUser.university.config.expert_connect,
      },
      {
        pathMatch: "/home/support",
        href: "/home/support",
        icon: HiOutlineSupport,
        label: "Support",
        condition: currentUser.university.config.support_page,
      },
      {
        pathMatch: "/home/ide",
        href: "/home/ide",
        icon: AiOutlineCode,
        label: "IDE",
        condition:
          currentUser.university.config.ide &&
          currentUser.university.config.compiler,
      },
      {
        pathMatch: "/home/AiInterview",
        href: "/home/AiInterview",
        icon: AiFillAudio,
        label: "AI Interview (new)",
        condition: true,
      },
      {
        pathMatch: "/home/builder",
        href: "/home/builder",
        icon: ReadCvLogo,
        label: "Resume Builder",
        condition: true,
      },
      {
        pathMatch: "/home/course/ed2cfc18-0314-4308-95fa-ab67f0069f0c/active",
        href: "/home/course/ed2cfc18-0314-4308-95fa-ab67f0069f0c/active",
        icon: HiOutlineVideoCamera,
        label: "Interviews",
        condition:
          currentUser.university.id === "b760c92e-46ec-43a9-9c6d-3d6cf933564a",
      },
    ];
  }, [currentUser]);

  return (
    <>
      <Sidebar
        collapsible="icon"
        {...props}
        className="bg-white text-gray-700 border-r border-gray-200"
      >
        <SidebarHeader className="flex justify-between flex-row items-center p-6 border-b border-gray-200">
          <img
            src={currentUser.university.icon}
            className={twMerge("object-contain w-full h-12")}
            alt=""
          />
        </SidebarHeader>
        <SidebarContent className="bg-transparent py-4">
          <NavMain items={navigations} ref={ref} />
        </SidebarContent>
        <SidebarFooter
          className={`flex gap-3 flex-row items-center border-t border-gray-200 ${
            state === "expanded" ? "p-6" : "p-4"
          }`}
        >
          <button
            onClick={() => {
              doSignOut();
            }}
            className="cursor-pointer flex gap-3 items-center hover:bg-gray-100 p-2 rounded-lg transition-colors duration-200"
          >
            <GoSignOut className="w-4 h-4" />
            {state === "expanded" && <span className="text-base">Log out</span>}
          </button>
        </SidebarFooter>
      </Sidebar>
    </>
  );
}
