
import { create } from "zustand";

const useDialogStore = create()((set) => ({
  dialog: null,
  setDialog: (dialog) => {
    set({ dialog });
  },
}));

const useDialog = (name) => {
  const dialog = useDialogStore((state) => {
    if (name.startsWith("custom.")) name = "custom";
    return state.dialog?.name === name ? state.dialog : null;
  });

  return {
    isOpen: !!dialog,
    mode: dialog?.mode,
    payload: dialog?.payload,
    open: (mode, payload) => {
      useDialogStore.setState({ dialog: { name, mode, payload } });
    },
    close: () => {
      useDialogStore.setState({ dialog: null });
    },
  };
};

export { useDialogStore, useDialog };
