import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
// import { t } from "@lingui/macro";
import { Plus } from "@phosphor-icons/react";
import { Button } from "src/components/ui/button";
import { cn } from "src/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import get from "lodash.get";

import { useDialog } from "./dialog";
import { useResumeStore } from "./resume";

import { getSectionIcon } from "./utils";
import { SectionListItem } from "./section-list-item";
import { SectionOptions } from "./section-options";

export const SectionBase = ({ id, title, description }) => {
  const { open } = useDialog(id);

  const setValue = useResumeStore((state) => state.setValue);
  const section = useResumeStore((state) =>
    get(state?.resume?.data?.sections, id)
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  if (!section) return null;

  const onDragEnd = (event) => {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = section.items.findIndex((item) => item.id === active.id);
      const newIndex = section.items.findIndex((item) => item.id === over.id);

      const sortedList = arrayMove(section.items, oldIndex, newIndex);
      setValue(`sections.${id}.items`, sortedList);
    }
  };

  const onCreate = () => {
    open("create", { id });
  };
  const onUpdate = (item) => {
    open("update", { id, item });
  };
  const onDuplicate = (item) => {
    open("duplicate", { id, item });
  };
  const onDelete = (item) => {
    open("delete", { id, item });
  };

  const onToggleVisibility = (index) => {
    const visible = get(section, `items[${index}].visible`, true);
    setValue(`sections.${id}.items[${index}].visible`, !visible);
  };

  return (
    <motion.section
      id={id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex-grow grid gap-y-6 w-full"
    >
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {getSectionIcon(id)}
          <h2 className="line-clamp-1 text-base font-bold">{section.name}</h2>
        </div>

        <div className="flex items-center gap-x-2">
          <SectionOptions id={id} />
        </div>
      </header>
      <main
        className={cn(
          "grid transition-opacity flex-grow",
          !section.visible && "opacity-50"
        )}
      >
        {section.items.length === 0 && (
          <Button
            variant="outline"
            className="gap-x-2 border-dashed py-6 leading-relaxed hover:bg-secondary-accent"
            onClick={onCreate}
          >
            <Plus size={14} />
            <span>Add a new item</span>
          </Button>
        )}

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToParentElement]}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={section.items}
            strategy={verticalListSortingStrategy}
          >
            <AnimatePresence>
              {section.items.map((item, index) => (
                <SectionListItem
                  key={item.id}
                  id={item.id}
                  visible={item.visible}
                  title={title(item)}
                  description={description?.(item)}
                  onUpdate={() => {
                    onUpdate(item);
                  }}
                  onDelete={() => {
                    onDelete(item);
                  }}
                  onDuplicate={() => {
                    onDuplicate(item);
                  }}
                  onToggleVisibility={() => {
                    onToggleVisibility(index);
                  }}
                />
              ))}
            </AnimatePresence>
          </SortableContext>
        </DndContext>
      </main>
      {section.items.length > 0 && (
        <footer className="flex items-center justify-end">
          <Button
            variant="outline"
            className="ml-auto gap-x-2"
            onClick={onCreate}
          >
            <Plus />
            <span>Add a new item</span>
          </Button>
        </footer>
      )}
    </motion.section>
  );
};
