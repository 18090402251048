import { Azurill } from "./azurill";
import { Bronzor } from "./bronzor";
import { Nosepass } from "./nosepass";
import { Ditto } from "./ditto";
import { Chikorita } from "./chikorita";
import { Glalie } from "./glalie";
import { Kakuna } from "./kakuna";
import { Gengar } from "./gengar";
import { Rhyhorn } from "./rhyhorn";
import { Pikachu } from "./pikachu";
import { Leafish } from "./leafish";
import { Onyx } from "./onyx";


export const getTemplate = (template) => {
  switch (template) {
    case "azurill": {
      return Azurill;
    }
    case "bronzor": {
      return Bronzor;
    }
    case "nosepass": {
      return Nosepass;
    }
    case "ditto": {
      return Ditto;
    }
    case "chikorita": {
      return Chikorita;
    }
    case "glalie": {
      return Glalie;
    }
    case "kakuna": {
      return Kakuna;
    }
    case "gengar": {
      return Gengar;
    }
    case "rhyhorn": {
      return Rhyhorn;
    }
    case "pikachu": {
      return Pikachu;
    }
    case "leafish": {
      return Leafish;
    }
    
    case "onyx": {
      return Onyx;
    }


    default: {
      return Azurill;
    }
  }
};
